import React from 'react'
import { Link } from 'gatsby'
import { css } from 'emotion'
import { Row, Col, ProductCard, DownloadPhase1PDF } from '../global'
import config from '../../siteConfig'

const centerText = css({
  textAlign: 'center',
})

const TrainingProducts = props => {
  return (
    <div className={`container`}>
      <h3 className={centerText}>Electrical Training Products</h3>
      <p className={centerText}>
        Our troubleshooting training techniques emphasize a "hands-on" approach <br /> to develop
        electrical troubleshooting skills faster.
      </p>
      <Row>
        <Col width="49%">
          <ProductCard
            title="Electrical SHORTCUTS"
            description='"Electrical SHORTCUTS" shows a technician how to troubleshoot vehicle electrical circuits on-the-vehicle with just a DMM and Current Clamp.'
            url="/products/shortcuts"
          />
        </Col>
        <Col width="49%">
          <ProductCard
            title="First Things First"
            description="FIRST THINGS FIRST is an auto/truck electrical  troubleshooting flip chart that focuses attention on problems in the primary electrical system."
            url="/products/first-things-first"
          />
        </Col>
      </Row>
      <Row justifyContent="center">
        <Col width="49%">
          <ProductCard
            title="THE STARTER KIT, H-111A"
            description="This is the first step in hands-on study of electrical circuit troubleshooting. The Starter Kit contains two fully assembled circuit boards that snap together and a DC power supply. The Hands-On Student Workbook, H-WB111A, is completely revised and updated. An Instructor Guide has all the answers."
            url="/products/h-111a"
          />
        </Col>
      </Row>
      <p className="text-center mb0">
        <Link className="inverse-link" to="/all-training">
          View All Training.
        </Link>
      </p>
    </div>
  )
}

export default TrainingProducts
