import React from 'react'
import { css } from 'emotion'
import mq from '../../utils/index'
import config from '../../siteConfig'
import { Row, Col, ProductBookCard, Button } from '../global'

const h1Style = css(
  mq({
    margin: ['0.5em 0 0.5em', '0.5em 0 0.5em', '0.5em 0 0.5em', '0.5em 0 0.5em'],
    fontSize: ['1.5rem', '2rem', '3rem', '3rem'],
  })
)

const pStyle = css(
  mq({
    position: 'absolute',
    zIndex: '999',
    bottom: '0',
    margin: '0',
    padding: ['1em 1em 0', '1em 1em 0', '1em 1em 0', '1em'],
    width: '100%',
    background: ['none', 'none', 'none', 'rgba(255,255,255,0.8)'],
    textAlign: ['right', 'right', 'right', 'center'],
  })
)

const aStyle = css({
  color: `${config.theme.colors.secondary} !important`,
})

const spanStyle = css(
  mq({
    display: ['block', 'block', 'block', 'inline'],
    fontSize: ['1.2rem', '1.3rem', '1.4rem', '1rem'],
    fontWeight: 'bold',
  })
)

const BannerContentAET = props => {
  return (
    <div style={{ position: 'relative' }}>
      <div className="container container-pb">
        <Row>
          <Col width="100%" align="left">
            <h1 className={h1Style}>Advanced Electronics Training Part 1 & 2</h1>
            <p className="boost" style={{ fontWeight: 'bold' }}>
              Combined, these 2 books have over 110 pages and over 120 illustrations of schematics
              and schemations presenting how a vehicle's electrical-electronic system functions with
              instructions on how to troubleshoot these circuits.
            </p>
            <Row>
              <Col width="65%" align="left">
                <p>
                  <Button
                    href="http://www.1shoppingcart.com/SecureCart/SecureCart.aspx?mid=2169DBD4-F088-4CA4-A011-56C1593411AC&pid=2806d388647147a884625fb8122560f8"
                    text="Buy Parts 1 & 2 for $149.00 + shipping"
                    type="secondary"
                  />
                </p>
                <p>
                  <Button
                    href="http://www.1shoppingcart.com/SecureCart/SecureCart.aspx?mid=2169DBD4-F088-4CA4-A011-56C1593411AC&pid=a7261f4f390843aeae721b4cfcf20b6b"
                    text="Buy Part 1 $68.00 + shipping"
                    type="secondary"
                  />
                </p>
                <p>
                  <Button
                    href="http://www.1shoppingcart.com/SecureCart/SecureCart.aspx?mid=2169DBD4-F088-4CA4-A011-56C1593411AC&pid=4ef17e8b83e545f7bc59a5e94c2d1ddf"
                    text="Buy Part 2 $88.00 + shipping"
                    type="secondary"
                  />
                </p>
              </Col>
              <Col width="35%" align="left">
                <Button
                  href="/products/advanced-electronics-training"
                  text="Learn More"
                  type="secondary"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <p className={pStyle}>
        <a className={aStyle} href="tel:9722769642">
          Call Today:<span className={spanStyle}>972-276-9642</span>
        </a>
        &nbsp;&nbsp;&nbsp;
        <a className={aStyle} href="mailto:vince@veejer.com">
          Email: vince@veejer.com
        </a>
      </p>
    </div>
  )
}

export default BannerContentAET
