import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { css } from 'emotion'
import BackgroundImage from 'gatsby-background-image'

const Banner = ({ className, children }) => {
  const { desktop, medium, small } = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "banner-optim.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 992) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        medium: file(relativePath: { eq: "banner-optim.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 768, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        small: file(relativePath: { eq: "banner-optim.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 576, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  // Art-Direction Array
  const backgroundArtDirectionStack = [
    small.childImageSharp.fluid,
    {
      ...medium.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
    {
      ...desktop.childImageSharp.fluid,
      media: `(min-width: 992px)`,
    },
  ]

  const bannerContainer = css({
    width: '100%',
  })

  return (
    <div className="banner">
      <BackgroundImage
        Tag="section"
        className={bannerContainer}
        fluid={backgroundArtDirectionStack}
        backgroundColor={`#040e18`}
        title="HANDS-ON ELECTRICAL TRAINING FOR AUTO, TRUCK, H.D. &amp; MARINE"
        id="adfullscreenbg"
        role="img"
        aria-label="HANDS-ON ELECTRICAL TRAINING FOR AUTO, TRUCK, H.D. &amp; MARINE"
        preserveStackingContext={true}
      >
        {children}
      </BackgroundImage>
    </div>
  )
}

export default Banner
