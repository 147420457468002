import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import PageCard from '../components/home/pageCard'
import TrainingProducts from '../components/home/trainingProducts'
import Banner from '../components/home/banner'
import BannerContentAET from '../components/home/bannerContentAET'
import { css } from 'emotion'
import { Row, Col, DownloadPhase1PDF } from '../components/global'
import WebinarNotice from '../components/global/webinarNotice'

const pageCardContainerStyles = css({
  padding: '2em 0 0',
})

const closeOutStyles = css({
  margin: '0.5em 0',
  textAlign: 'center',
  fontSize: '2rem',
})

const spanStyles = css({
  fontSize: '1rem',
  lineHeight: '1em',
  display: 'block',
  margin: '1em 4em 0',
  fontStyle: 'italic',
})

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <WebinarNotice />
    <Banner>
      <BannerContentAET />
    </Banner>
    <div className="container">
      <p className={closeOutStyles}>
        <Link to="products/circuit-board-trainer-inventory-clearance">Close-out Special Offer</Link>
        <br />
        <span className={spanStyles}>
          Here's the last chance to obtain a complete 3 hands-on kit electrical training program
          with books at a 25% discount.
        </span>
      </p>
      <DownloadPhase1PDF />
      <div className={pageCardContainerStyles}>
        <Row>
          <Col width="32%">
            <PageCard
              icon="technicians"
              title="For Technicians"
              description="Learn about our technician training programs in electrical systems troubleshooting and electronics repair."
              url="/technicians"
            />
          </Col>
          <Col width="32%">
            <PageCard
              icon="shop-owners"
              title="For Shop Owners"
              description="Our training programs can train your technicians to be more proficient in electrical repair - saving labor hours, down time and fewer good parts replaced."
              url="/shop-owners"
            />
          </Col>
          <Col width="32%">
            <PageCard
              icon="teachers"
              title="For Teachers"
              description='We have the electrical-electronics curriculum that your students will need when they enter the job market. Be "THAT" teacher that showed them how!'
              url="/teachers"
            />
          </Col>
        </Row>
        <p className="text-center">
          <Link to="/testimonials">Read Our Customer Testimonials</Link>
        </p>
      </div>
    </div>
    <div className="container-red container-last">
      <TrainingProducts />
    </div>
  </Layout>
)

export default IndexPage
