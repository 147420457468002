import React from 'react'
import { Icon } from '../global'
import { Link } from 'gatsby'

const WebinarNotice = () => {
  return (
    <>
      <div className="notice webinars">
        <div className="container container-thin">
          <div className="inner">
            <Icon icon="webinar" />
            <p>
              Veejer Enterprises now offers electrical training{' '}
              <Link to="/webinars">webinars!</Link>
            </p>
            <Icon icon="webinar" />
          </div>
        </div>
      </div>
    </>
  )
}

export default WebinarNotice
